export const TRACK_THING_DETAIL_DEFAULT_TEMPLATE = [
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [2, 2],
    spacing: 2,
    sx: { minWidth: 0 },
    children:
      [
        {
          op: 'ui',
          name: 'ThingCard',
          entity: {
            op: 'context',
            path: 'entity',
          },
          entityType: {
            op: 'context',
            path: 'entityType',
          },
          portal: {
            op: 'context',
            path: 'portal',
          },
        },
        {
          op: 'ui',
          name: 'GroupsCard',
          groupIds: {
            op: 'context',
            path: 'groupIds',
          },
          noGroupsText: {
            op: 'context',
            path: 'noGroupsText',
          },
          isClickable: {
            op: 'context',
            path: 'isClickable',
          },
        },
        {
          op: 'context',
          path: 'personThingCard',
        },
      ],
  },
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [3, 3],
    spacing: 2,
    sx: { minWidth: 0 },
    children:
      [
        {
          op: 'context',
          path: 'thingInlayMap',
        },
        {
          op: 'ui',
          name: 'IfFlag',
          flag: 'track-thing-detail-recent-metrics-feature',
          children: {
            op: 'ui',
            name: 'RecentEventCard',
            headers: [{
              op: 'ui',
              name: 'TableCell',
              children: {
                op: 'translate',
                translation: 'common:component.recent-events.labels.metric',
              },
            },{
              op: 'ui',
              name: 'TableCell',
              children: {
                op: 'translate',
                translation: 'common:component.recent-events.labels.value',
              },
            },{
              op: 'ui',
              name: 'TableCell',
              children: {
                op: 'translate',
                translation: 'common:component.recent-events.labels.last-reported',
              },
              align: 'right',
            }],
            eventRows: [{
              featureTypeId: 'number-accumulator-v0',
              feature: 'distance',
              eventTypeId: 'update',
              path: 'data.value',
              formatter: 'distance',
              multiplier: 0.001,
            },
            {
              featureTypeId: 'activity-accumulator-v0',
              feature: 'engine-running',
              eventTypeId: 'update',
              path: 'data.duration',
              formatter: 'duration',
            },
            {
              featureTypeId: 'number-gauge-v0',
              feature: 'fuel-level',
              eventTypeId: 'update',
              path: 'data.value',
              formatter: 'percentage',
            },
            {
              featureTypeId: 'number-gauge-v0',
              feature: 'ad-blue-level',
              eventTypeId: 'update',
              path: 'data.value',
              formatter: 'percentage',
            },
            ],
            lastEvents: {
              op: 'context',
              path: 'lastThingEvents',
            },
          },
        },
        {
          op: 'context',
          path: 'thingMedia',
        },
        {
          op: 'context',
          path: 'thingAlerts',
        },
        {
          op: 'context',
          path: 'thingEvents',
        },
        {
          op: 'context',
          path: 'thingServiceHistory',
        },
      ],
  },
];

export const ADMIN_THING_DETAIL_DEFAULT_TEMPLATE = [
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [2, 2],
    sx: { minWidth: 0 },
    spacing: 2,
    children:
    [
      {
        op: 'context',
        path: 'personDetailsCard',
      },
      {
        op: 'ui',
        name: 'ThingPersonCard',
        thing: {
          op: 'context',
          path: 'data',
        },
        portal: {
          op: 'context',
          path: 'portal',
        },
        deleted: {
          op: 'context',
          path: 'deleted',
        },
      },
    ],
  },
  {
    op: 'ui',
    name: 'Stack',
    direction: 'column',
    flex: [3, 3],
    sx: { pt: 2 },
    children:
    [
      {
        op: 'context',
        path: 'thingGroupLists',
      },
      {
        op: 'context',
        path: 'serviceHistoryCard',
      },
    ],
  },
];
