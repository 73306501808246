/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Undefinable } from '../types';

export interface SerializationOptions<T, S> {
  fromSerializable: (value: S) => T;
  toSerializable: (value: T) => S;
}

const deserialize = <T, S>(serialized: unknown, fromSerializable?: (value: S) => T): Undefinable<T> => {
  if (!serialized || typeof serialized !== 'string') return undefined;
  return fromSerializable ? fromSerializable(JSON.parse(serialized) as S) : JSON.parse(serialized) as T;
};

const serialize = <T, S>(value: T, toSerializable?: (value: T) => S): string => {
  return JSON.stringify(toSerializable ? toSerializable(value) : value);
};

let historyState = Object.assign({}, history.state as Record<string, any>);

export const useHistoryState = <T, S = T>(key: string, defaultValue: T, replace = true, options?: SerializationOptions<T, S>): [T, Dispatch<SetStateAction<T>>] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const [value, setValue] = useState<T>(() => deserialize(history.state?.[key], options?.fromSerializable) ?? defaultValue);

  useEffect(() => {
    historyState = Object.assign({}, history.state as Record<string, any>);
  }, []);

  useEffect(() => {
    historyState = {...historyState, [key]: serialize(value, options?.toSerializable)};
    if (replace) {
      history.replaceState(historyState, '');
    } else {
      history.pushState(historyState, '');
    }
  }, [key, value]);

  return [value, setValue];
};
