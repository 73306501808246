/* eslint-disable react-hooks/exhaustive-deps */
import { Id, PaginatedResponse } from '@eagle/api-types';
import { Button, Dialog, DialogActions, DialogContent, ListItem, Paper, Stack, Typography } from '@mui/material';
import Axios from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { API_CALL_TEXT_LENGTH } from '../../constants';
import { useSmallScreen } from '../../hooks';
import { FindItemsDeferredResult, Query } from '../../pages';
import { SearchProvider, useSearch } from '../../pages/list/use-search';
import { Alert } from '../alert';
import { DialogTitle } from '../dialog';
import { DialogList } from '../dialog-list';
import { ErrorMessage } from '../error-message';
import { SlideTransitionComponent } from './slide-transition-component';
import { AssignDialogProps, ResultRowProps } from './types';

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AssignResultRow<T>({
  additionalContent,
  assigned,
  assignedElement,
  assignNewEntityElement,
  data,
  itemHeader,
  ...props
}: ResultRowProps<T>): JSX.Element {
  return (
    <Paper data-testid={props['data-testid']} elevation={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', mb: '1em' }}>
      <ListItem sx={{ userSelect: 'none' }}>
        <Stack direction="row" sx={{ display: 'flex', flexGrow: 1 }} spacing={2}>
          {itemHeader(data)}
          {additionalContent && additionalContent(data)}
        </Stack>
        <Stack sx={{ width: '20%', alignItems: 'flex-end' }}>
          {assigned
            ? assignedElement
            : assignNewEntityElement(data)
          }
        </Stack>
      </ListItem>
    </Paper>
  );
}

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AssignDialogView<T extends Id<string>>({
  additionalItemContent,
  apiFilters,
  apiUri,
  assignableText,
  assignedElement,
  assignNewEntityElement,
  error,
  handleClose,
  idsToCheck,
  itemHeader,
  open,
  shortTitle,
  textTitle,
  ...props
}: AssignDialogProps<T>): JSX.Element {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const smallScreen = useSmallScreen();
  const {
    headerAction,
    headerEntity,
    headerEntities,
    headerEntityToSelect,
  } = assignableText;

  const { setText } = useSearch();

  const internalClose = (): void => {
    setText('');
    handleClose();
  };

  useEffect(() => {
    setText('');
  }, []);

  const findItems = ({ pagination, search }: Omit<Query, 'filters'>): FindItemsDeferredResult<T> => {
    const cancelToken = Axios.CancelToken.source();
    const defaultParams = {
      ...pagination,
      search,
    };

    const params = apiFilters
      ? { ...defaultParams, filter: apiFilters }
      : defaultParams;

    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<PaginatedResponse<T>>(apiUri, {
        cancelToken: cancelToken.token,
        params,
      }).then((response) => {
        const headers = response.headers as Record<string, any>;
        const matchCount = response.data.count ?? Number.parseInt(headers['x-match-count'] as string, 10);
        const resultDescription = search
          ? t('common:common.hint.list.search', { entity: headerEntity, search })
          : t('common:common.hint.list.no-search', { entity: headerEntity });
        return {
          result: {
            results: response.data.items ?? response.data,
            itemCount: matchCount,
          },
          resultDescription,
        };
      }),
    };
  };

  const dialogTitle = textTitle ? textTitle : shortTitle
    ? t('common:component.assign-dialog.heading.short', { entity: headerEntity.toLowerCase(), action: headerAction, name: headerEntityToSelect })
    : t('common:component.assign-dialog.heading.main', { entity: headerEntity.toLowerCase(), action: headerAction, name: headerEntityToSelect });

  return (
    <Dialog
      fullScreen={smallScreen}
      fullWidth
      data-testid={props['data-testid']}
      onClose={internalClose}
      open={open}
      TransitionComponent={SlideTransitionComponent}
      sx={{ maxHeight: '55vh', height: 'max-content', m: 'none', '& .MuiDialog-container': { height: 'auto' } }}
    >
      <DialogTitle title={dialogTitle} />
      <DialogContent dividers sx={{ px: 0 }}>
        {error
          ? <Alert severity="error" ><ErrorMessage error={error} /></Alert>
          : <DialogList<T>
            limit={3}
            onQueryChanged={findItems}
            renderContent={(items, _, text) => {
              if (!items || !text) return <Typography color="text.secondary">{t('common:component.lookup.hint.initial')}</Typography>;
              if (text && text.length < API_CALL_TEXT_LENGTH) return <Alert severity="info">{t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH })}</Alert>;
              if (text && !items.length) return <Alert severity="info">{t('common:common.hint.list.none', { entity: headerEntities.toLowerCase() })}</Alert>;
              return <>
                {items.map((item, i) => (
                  <AssignResultRow<T>
                    data-testid="assign-dialog-search-item"
                    additionalContent={additionalItemContent}
                    assigned={idsToCheck.includes(item._id)}
                    assignedElement={assignedElement}
                    assignNewEntityElement={assignNewEntityElement}
                    data={item}
                    itemHeader={itemHeader}
                    key={i}
                  />
                ))}
              </>;
            }}
            triggerClearText={1}
          />
        }
      </DialogContent>
      <DialogActions sx={{ px: 3 }}>
        <Button
          data-testid="close-button"
          onClick={internalClose}
          variant="text"
          sx={{ userSelect: 'none' }}
        >
          {t('common:common.action.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AssignDialog<T extends Id<string>>(props: AssignDialogProps<T>): JSX.Element {
  return (
    <SearchProvider dataKey="assign-dialog">
      <AssignDialogView {...props} />
    </SearchProvider>
  );
}
