export const THING_INLAY_MAP = {
  op: 'if-feature-data',
  feature: 'tracking',
  then: {
    op: 'ui',
    name: 'InlayMap',
    'data-testid': 'inlay-map-container',
    center: {
      op: 'latitude-longitude',
      latitude: {
        op: 'feature-event',
        feature: 'tracking',
        eventTypeId: 'location-update',
        path: 'location.latitude',
      },
      longitude: {
        op: 'feature-event',
        feature: 'tracking',
        eventTypeId: 'location-update',
        path: 'location.longitude',
      },
    },
    zoom: 15,
    allowSearch: false,
    drawerLayerSelection: true,
    customMarker: {
      op: 'ui',
      name: 'CustomMarker',
      pinPosition: {
        op: 'latitude-longitude',
        latitude: {
          op: 'feature-event',
          feature: 'tracking',
          eventTypeId: 'location-update',
          path: 'location.latitude',
        },
        longitude: {
          op: 'feature-event',
          feature: 'tracking',
          eventTypeId: 'location-update',
          path: 'location.longitude',
        },
      },
      color: {
        op: 'if',
        condition: {
          op: 'before-past',
          timestamp: {
            op: 'feature-latest-occurred',
            feature: 'tracking',
            havingPath: 'data.location',
          },
          duration: 'P7D',
        },
        then: {
          op: 'theme-property',
          path: 'states.uncertain',
        },
      },
      indicator: {
        op: 'if',
        condition: {
          op: 'before-past',
          timestamp: {
            op: 'feature-latest-occurred',
            feature: 'tracking',
            havingPath: 'data.location',
          },
          duration: 'P7D',
        },
        then: null,
        else: {
          op: 'if',
          condition: { op: 'macro:is-power-on' },
          then: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'theme-property',
              path: 'states.on',
            },
            else: {
              op: 'theme-property',
              path: 'states.paused',
            },
          },
          else: {
            op: 'if',
            condition: { op: 'macro:is-moving' },
            then: {
              op: 'theme-property',
              path: 'states.on',
            },
            else: {
              op: 'theme-property',
              path: 'states.off',
            },
          },
        },
      },
      children: {
        op: 'ui',
        name: 'LeafletTooltip',
        direction: 'right',
        offset: [15, -10],
        opacity: 1,
        permanent: true,
        children: {
          op: 'ui',
          name: 'Stack',
          children: [
            {
              op: 'ui',
              name: 'EventAddress',
              loadingComponent: {
                op: 'ui',
                name: 'Skeleton',
                variant: 'text',
                width: 120,
              },
              location: {
                op: 'feature-event',
                feature: 'tracking',
                eventTypeId: 'location-update',
                path: 'location',
              },
            },
            {
              op: 'ui',
              name: 'Typography',
              variant: 'caption',
              children: [
                {
                  op: 'translate',
                  translation: 'common:component.last-contact.labels.contact-time',
                },
                ': ',
                {
                  op: 'ui',
                  name: 'FormatTimestamp',
                  format: 'relative',
                  value: {
                    op: 'feature-latest',
                    feature: 'tracking',
                    havingPath: 'data.location',
                    path: 'occurred',
                    excludeEventTypes: ['inaccurate-location-update'],
                  },
                },
              ],
            },

          ],
        },
      },
    },
    overlay: {
      op: 'ui',
      name: 'MapOverlay',
      backgroundColor: {
        op: 'theme-property',
        path: 'dataCard.background',
      },
      tooltip: [
        {
          op: 'translate',
          translation: 'common:component.last-contact.labels.contact-time',
        },
        {
          op: 'ui',
          name: 'FormatTimestamp',
          format: 'relative',
          value: {
            op: 'last-event-time',
          },
        }],
      children: [
        {
          op: 'ui',
          name: 'DataCard',
          'data-testid': 'map-popup-thing-status',
          backgroundColor: {
            op: 'theme-property',
            path: 'palette.background.paper',
          },
          wrap: true,
          title: {
            op: 'if',
            condition: { op: 'macro:is-power-on' },
            then: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'translate',
                translation: 'component.events.labels.moving',
                options: {
                  ns: 'common',
                },
              },
              else: {
                op: 'translate',
                translation: 'page.thing-map.state.stationary',
                options: {
                  ns: 'track',
                },
              },
            },
            else: {
              op: 'translate',
              translation: 'event-descriptions-v2.tracking-v0.power-off.label',
              options: {
                ns: 'common',
              },
            },
          },
          hint: {
            op: 'if',
            condition: {
              op: 'macro:is-power-on',
            },
            then: {
              op: 'if',
              condition: {
                op: 'macro:is-moving',
              },
              then: [{
                op: 'translate',
                translation: 'page.thing-map.indicator.powered-on-moving.label',
                speed: {
                  op: 'format-speed',
                  value: {
                    op: 'feature-latest',
                    feature: 'tracking',
                    havingPath: 'data.velocity',
                    path: 'data.velocity.speed',
                    excludeEventTypes: ['inaccurate-location-update'],
                  },
                },
                options: {
                  ns: 'track',
                },
              }],

              else: {
                op: 'translate',
                translation: 'page.thing-map.indicator.stationary.label',
                options: {
                  ns: 'track',
                },
              },
            },
            else: {
              op: 'translate',
              translation: 'page.thing-map.indicator.powered-off-not-moving.label',
              options: {
                ns: 'track',
              },
            },
          },
          avatar: {
            op: 'ui',
            name: 'Avatar',
            icon: {
              op: 'if',
              condition: { op: 'macro:is-power-on' },
              then: {
                op: 'if',
                condition: { op: 'macro:is-moving' },
                then: 'PlayIcon',
                else: 'PauseIcon',
              },
              else: 'PowerIcon',
            },
            iconColor: {
              op: 'if',
              condition: {
                op: 'macro:is-power-on',
              },
              then: {
                op: 'if',
                condition: {
                  op: 'macro:is-moving',
                },
                then: {
                  op: 'theme-property',
                  path: 'states.on',
                },
                else: {
                  op: 'theme-property',
                  path: 'states.paused',
                },
              },
              else: {
                op: 'theme-property',
                path: 'dataCard.contrastText',
              },
            },
            background: 'transparent',
          },
        },
      ],
    },
    entityId: {
      op: 'context',
      path: 'entityId',
    },
  },
  else: {
    op: 'ui',
    name: 'InlayMap',
    zoom: 15,
    drawerLayerSelection: true,
    allowSearch: false,
    overlayLabel: {
      op: 'translate',
      translation: 'component.map.hint.no-location',
      options: {
        ns: 'common',
      },
    },
  },
};
