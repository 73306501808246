import { Locale } from 'date-fns';
import * as locales from 'date-fns/locale';
import { isString } from 'lodash';
import { Undefinable } from '../types';
import { getLocalStorageItem } from './local-storage';

export const getLocale = (): Locale => {
  return navigator.languages.reduce<Undefinable<Locale>>(
    (acc, item: string) => acc ?? (locales as Record<string, Locale>)[item.replace('-', '')],
    undefined,
  ) ?? locales.enUS;
};

export const hourCycle = (): Undefinable<boolean> => {
  return new Intl.DateTimeFormat(getLocale().code, { hour: 'numeric' }).resolvedOptions().hour12;
};

export const kilometersToMiles = (kilometers: number): number => kilometers * 0.621371;

export const kphToMph = (speedKph: number): number => speedKph * 0.621371;

export const getPortalLocale = (): string => {
  const savedPortalLanguage = getLocalStorageItem<string>('portal-language-preference');
  return (!savedPortalLanguage || !isString(savedPortalLanguage)) ? navigator.language : savedPortalLanguage;
};

export const getLanguageCode = (locale: string): string => {
  const [languageCode] = locale.split(/[-_]/);
  return languageCode;
};

export const getPortalLanguage = (): string => {
  const portalLanguage = getPortalLocale();
  return getLanguageCode(portalLanguage);
};
