import { Operator } from '@jexop/core';
import { isNumber, isString } from 'lodash';
import { Nullable } from '../types';
import { formatDistanceString, UnitDisplay } from '../util';

export const formatDistance: Operator<number | string> = (props): Nullable<number | string> => {
  if (!isNumber(props.meters)) {
    return null;
  }

  const unit = isString(props.unit) && ['kilometer', 'mile'].includes(props.unit) ? props.unit : undefined;
  const display = isString(props.display) && ['long', 'short', 'narrow'].includes(props.display) ? props.display as UnitDisplay : undefined;

  return formatDistanceString(props.meters, unit, display);
};
