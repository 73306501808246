import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';

interface Props extends ButtonProps, PropsWithChildren {
  isLoading?: boolean;
  loadingCaption?: ReactNode;
  onClick?: () => void;
  task?: () => Promise<unknown>;
}

interface ViewProps extends Props {
  'data-testid'?: string;
  isLoading: boolean;
  size?: ButtonProps['size'];
}

export const LoadingButtonView: FC<ViewProps> = (props) => {
  const { children, disabled, isLoading, loadingCaption, sx, task, variant, ...rest } = props;

  return (
    <Button
      {...rest}
      disabled={isLoading || disabled}
      onClick={!isLoading ? (e) => { e.preventDefault(); return task?.(); } : undefined}
      sx={{ display: 'flex', alignItems: 'center', ...sx }}
      variant={variant ?? 'outlined'}
    >
      {isLoading
        ? <>
          <CircularProgress color="inherit" role="status" size="0.8rem" sx={{ marginRight: '0.5rem' }} />
          <span>{loadingCaption || <>&nbsp;</>}</span>
        </>
        : children
      }
    </Button>
  );
};

export const LoadingButton: FC<Props> = (props) => {
  const { children, isLoading: isLoadingProp, onClick, task, ...rest } = props;
  const [isLoading, setLoading] = useState(isLoadingProp ?? false);

  useEffect(() => setLoading(isLoadingProp ?? false), [isLoadingProp]);

  const wrappedTask = (): Promise<unknown> => {
    if (isLoadingProp !== undefined) {
      onClick?.();
      return Promise.resolve();
    }
    setLoading(true);
    return task ? task().catch().finally(() => setLoading(false)) : Promise.resolve();
  };

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <LoadingButtonView
      {...rest}
      isLoading={isLoading}
      task={wrappedTask}
    >
      {children}
    </LoadingButtonView>
  );
};

export default LoadingButton;
