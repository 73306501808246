import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode } from 'react';

interface IfFlagsProps {
  children: ReactNode;
  fallback?: ReactNode;
  flags: Record<string, boolean | string | number>;
  some?: boolean;
}

export const IfFlags: FC<IfFlagsProps> = ({ children, fallback = <></>, flags: expected, some }) => {
  const actual = useFlags();

  const match = some
    ? Object.keys(expected).every((flag) => expected[flag] === actual[flag])
    : Object.keys(expected).some((flag) => expected[flag] === actual[flag]);

  if (!match) return <>{fallback}</>;
  return <>{children}</>;
};
