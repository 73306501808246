/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { WatRequest } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { FC, useMemo, useState } from 'react';
import { useAuthenticated } from '../../auth';
import { useConfig, usePromise, useScript } from '../../hooks';
import { ErrorPage } from '../../pages';
import { Nullable, Undefinable } from '../../types';
import { addLocalStorageItem, CodedError, getLocalStorageItem } from '../../util';
import { getSisenseLanguageCode } from '../../util/sisense';
import { useBoolFlag } from '../flags';
import { MiddleSpinner } from '../middle-spinner';
import { EventArguments, SisenseFrameConstructor } from './types';

interface Props {
  dashboardId: string;
  type: 'dashboards' | 'overview' | 'reports';
}

interface DashboardProps extends Props {
  sisenseUrl: string;
  wat: string;
  theme?: string;
}

interface PersistingFilters {
  filters: string;
  timeStamp: number;
}

interface FilterObject {
  disabled: boolean;
  isCascading: boolean;
  jaql?: JaqlObject;
  levels?: JaqlObject[];
}

interface JaqlObject {
  title: string;
  dim: string;
  datatype: string;
  filter: {
    startsWith?: string;
  };
}

export const Dashboard: FC<DashboardProps> = ({ dashboardId, sisenseUrl, wat, theme, type }) => {
  const [iframeRef, setIframeRef] = useState<Nullable<HTMLIFrameElement>>(null);
  const { enqueueSnackbar } = useSnackbar();
  const isPersistingFilters = useBoolFlag('track-dashboard-persisting-filters');
  const v2DashSwitching = useBoolFlag('portals-dashboard-switching-enhancements-temporary-20240918') ?? false;
  const sisenseEmbed = window['sisense.embed'] as Undefinable<{ SisenseFrame: SisenseFrameConstructor }>;

  useMemo(() => {
    if (!iframeRef) return undefined;
    if (!sisenseEmbed) {
      console.log("window['sisense.embed'] is undefined");
      return undefined;
    }

    const { SisenseFrame } = sisenseEmbed;
    const sisenseFrame = new SisenseFrame({
      // Sisense application URL, including protocol and port if required
      url: sisenseUrl,
      // Web access token:
      wat,
      // OID of dashboard to load initially
      dashboard: dashboardId,
      // Which panels to show in the iFrame
      settings: {
        showLeftPane: false,
        showToolbar: true,
        showRightPane: true,
      },
      element: iframeRef,
      theme,
      language: getSisenseLanguageCode(),
    });
    sisenseFrame.render().catch((err: Error) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    });

    sisenseFrame.render().then(() => {
      if (!isPersistingFilters) { return; }
      let initialLoad = true;
      // Encodes an array of filters to the appropriate section of the local storage item 'persisting-filters' with its timestamp
      const saveFiltersToLocalStorage = (filters: FilterObject[]): void => {
        // If filters is an empty array, set that type in local storage to null
        if (Array.isArray(filters) && filters.length === 0) {
          const storageData = { filters: null };
          addLocalStorageItem('persisting-filters', storageData);
          return;
        }
        // Encode Filters and timestamp and set it to the correct data structure
        const encodedFilters = btoa(JSON.stringify(filters));
        const filterComponent: PersistingFilters = { filters: encodedFilters, timeStamp: Date.now() };
        const filtersAsRecord: Record<string, unknown> = {
          filters: filterComponent.filters,
          timeStamp: filterComponent.timeStamp,
        };

        addLocalStorageItem('persisting-filters', filtersAsRecord);
      };

      const saveDashboard = (args: EventArguments): void => {
        if (!v2DashSwitching) { return; }
        let dashId;
        if (type === 'dashboards') {
          dashId = { dashboards: args.dashboard.oid };
        }
        else if (type === 'reports') {
          dashId = { reports: args.dashboard.oid };
        }
        else {
          dashId = { analytics: args.dashboard.oid };
        }
        addLocalStorageItem('current-dashboards', dashId);
      };

      // Returns if a particular filter exists in an array of filters with a particular dim / set of dims
      const filterExistsInSaved = (currentFilter: FilterObject, savedFilters: FilterObject[]): boolean => {
        if (!currentFilter.jaql) {
          return currentFilter.levels?.some((currentLevel) => {
            return savedFilters.some((savedFilter) => {
              return savedFilter.levels?.some((savedLevel) => savedLevel.dim === currentLevel.dim);
            });
          }) ?? false;
        }
        return savedFilters.some((savedFilter) => savedFilter.jaql?.dim === currentFilter.jaql?.dim);
      };

      // Handles changes when the filter change event is called
      const handleDashboardFiltersChanged = (args: EventArguments): void => {
        if (initialLoad) return;

        const currentFilters: FilterObject[] = args.dashboard.filters;
        const savedFilters: PersistingFilters = getLocalStorageItem('persisting-filters');
        const filterObjectArray: FilterObject[] = savedFilters?.filters
          ? JSON.parse(atob(savedFilters.filters)) as FilterObject[]
          : [];

        const combinedFilters: FilterObject[] = currentFilters.reduce((acc: FilterObject[], currentFilter: FilterObject) => {
          const matchingIndex = filterObjectArray.findIndex(
            (f: FilterObject) => f.jaql?.dim === currentFilter.jaql?.dim ||
              (f.levels && currentFilter.levels &&
                f.levels.some((level) => currentFilter.levels?.some((cl) => cl.dim === level.dim))
              ),
          );

          if (matchingIndex !== -1) {
            // If a matching filter is found, replace the saved filter with the current one
            acc[matchingIndex] = currentFilter;
          } else {
            // If no match is found, add the current filter to the accumulated filters
            acc.push(currentFilter);
          }

          return acc;
        }, [...filterObjectArray]); // Start with a copy of filterObjectArray

        const filtersChanged = JSON.stringify(currentFilters) !== JSON.stringify(filterObjectArray);

        // Save the filters to local storage only if there is a change
        if (filtersChanged) {
          saveFiltersToLocalStorage(combinedFilters);
        }
      };

      // Handles changes when the dashboardloaded event is called
      const handleDashboardLoaded = (args: EventArguments): void => {
        const savedFilters: PersistingFilters = getLocalStorageItem('persisting-filters');
        const currentFilters = args.dashboard.filters;

        if (savedFilters?.filters) {
          const decodedFilters: FilterObject[] = JSON.parse(atob(savedFilters.filters)) as FilterObject[];
          const matchingFilters: FilterObject[] = currentFilters
            .map((currentFilter: FilterObject) =>
              decodedFilters.find((decodedFilter: FilterObject) => filterExistsInSaved(currentFilter, [decodedFilter])),
            ).filter((filter): filter is FilterObject => Boolean(filter));

          const filterAge = Date.now() - savedFilters.timeStamp;
          const isExpired = filterAge >= 24 * 60 * 60 * 1000;
          if (!isExpired) {
            sisenseFrame.dashboard.applyFilters(matchingFilters, true);
          } else {
            saveFiltersToLocalStorage([]);  // Reset filters
          }
        }
        initialLoad = false;
      };

      sisenseFrame.dashboard.on('dashboardfilterschanged', handleDashboardFiltersChanged);
      sisenseFrame.dashboard.on('dashboardloaded', handleDashboardLoaded);
      sisenseFrame.dashboard.on('dashboardloaded', saveDashboard);
    }).catch((err: Error) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    });
  }, [dashboardId, iframeRef, sisenseEmbed, wat]);

  return <iframe ref={setIframeRef} style={{ border: 'none', height: '100%' }} />;
};

export const SisenseDashboard: FC<Props> = ({ dashboardId, type }) => {
  const config = useConfig() as { sisense: { baseUrl: string; theme?: string } };
  const sisenseUrl = config?.sisense?.baseUrl;
  const sisenseTheme = config?.sisense?.theme;
  if (!sisenseUrl) return <ErrorPage error={new CodedError('Reporting is not configured', 'error-sisense-not-configured')} />;

  const scriptUrl = `${sisenseUrl}/js/frame.js`;
  const state = useScript(scriptUrl);
  const { restClient } = useAuthenticated();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const requestData: WatRequest = { timeZone };

  const [data, error] = usePromise(() => restClient.sisense.wat(requestData), [dashboardId]);

  if (state.error) {
    return <ErrorPage error={new CodedError('Unable to load report', 'error-loading-analytics-scripts')} />;
  }
  if (error) return <ErrorPage error={error} />;
  if (!data) return <MiddleSpinner />;

  const { wat } = data;
  return <Dashboard dashboardId={dashboardId} sisenseUrl={sisenseUrl} wat={wat} theme={sisenseTheme} type={type} />;
};
