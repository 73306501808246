/* eslint-disable react-hooks/exhaustive-deps */
import { AlertResponse } from '@eagle/core-data-types';
import { Box, Button, Pagination, PaginationItem, Typography } from '@mui/material';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { ALERTS_DAYS_LIMIT } from '../../constants';
import { evaluate } from '../../evaluator';
import { RECENT_EVENT_DATA_MAX_DAYS_FLAG } from '../../feature-flags';
import { useMaxScreen, useUiTemplate } from '../../hooks';
import { ALERT_LIST_HEADERS, ALERT_LIST_ITEM, ALERT_LIST_ITEM_CARD, ALERT_LIST_RECENT_HEADERS, ALERT_LIST_RECENT_ITEM } from '../../ui-templates';
import { testid } from '../../util';
import { Pagination as PaginationInterface } from '../entity-search/types';
import { useFlags, useNumberFlag } from '../flags';
import { MiddleSpinner } from '../middle-spinner';
import { Table } from '../table';
import { AlertListItem } from './alert-list-item';
import { AlertsTableLayoutDesktop } from './layout-desktop';
import { AlertsTableLayoutMobile } from './layout-mobile';

interface Props {
  alerts: AlertResponse[];
  isLoading: boolean;
  matchCount: number;
  pagination: PaginationInterface;
  renderTitle?: (alerts: AlertResponse[], matchCount: number) => ReactNode;
  setPagination: (pagination: PaginationInterface) => void;
  showFeature: boolean;
  showGroups: boolean;
  showPerson: boolean;
  showThing: boolean;
  smallTable?: boolean;
  stickyHeader?: boolean;
  handleShowMore: () => void;
  isRecentAlertsView: boolean;
}

export const AlertsTableView: FC<Props> = ({
  alerts,
  isLoading,
  matchCount,
  pagination,
  renderTitle,
  showFeature,
  showGroups,
  showPerson,
  showThing,
  smallTable = false,
  stickyHeader = true,
  setPagination,
  handleShowMore,
  isRecentAlertsView,
}): JSX.Element => {
  const mediumScreen = useMaxScreen('md');
  const { t } = useTranslation(['common']);
  const flags = useFlags();
  const listPageScroll = document.getElementById('list-page-scroll') as HTMLDivElement;
  const maxDays = useNumberFlag(RECENT_EVENT_DATA_MAX_DAYS_FLAG) || ALERTS_DAYS_LIMIT;
  const { template: headersTemplate, loaded: headersLoaded } = useUiTemplate('alert-list-headers', ALERT_LIST_HEADERS);
  const { template: rowTemplate, loaded: rowLoaded } = useUiTemplate('alert-list-item', ALERT_LIST_ITEM);
  const { template: headersRecentTemplate, loaded: headersRecentLoaded } = useUiTemplate('alert-list-recent-headers', ALERT_LIST_RECENT_HEADERS);
  const { template: rowRecentTemplate, loaded: rowRecentLoaded } = useUiTemplate('alert-list-recent-item', ALERT_LIST_RECENT_ITEM);
  const { template: cardTemplate, loaded: cardLoaded } = useUiTemplate('alert-list-card', ALERT_LIST_ITEM_CARD);

  const headers = isRecentAlertsView ? headersRecentTemplate : headersTemplate;
  const rows = isRecentAlertsView ? rowRecentTemplate : rowTemplate;

  const paginationComponent = useMemo(
    () => matchCount > pagination.limit
      ? <Pagination
        count={Math.ceil(matchCount / pagination.limit)}
        disabled={isLoading}
        onChange={(_, page) => {
          setPagination({ limit: pagination.limit, skip: pagination.limit * (page - 1) });
          listPageScroll && scrollIntoView(listPageScroll, { behavior: 'smooth', block: 'start' });
        }}
        renderItem={(item) => (
          <PaginationItem data-testid={testid`pagination-item-${item.type}${item.type === 'page' ? `-${item.page ?? ''}` : ''}`}
            {...item}
          />
        )}
        page={Math.floor(pagination.skip / pagination.limit) + 1}
        showFirstButton={!mediumScreen}
        showLastButton={!mediumScreen}
        size={mediumScreen ? 'small' : 'large'}
        sx={{ my: .5 }}
      />
      : undefined,
    [isLoading, pagination, mediumScreen, matchCount],
  );

  const showAllButtonComponent = useMemo(
    () => {
      const totalPages = Math.ceil(matchCount / pagination.limit);
      const currentPage = Math.floor(pagination.skip / pagination.limit) + 1;
      const isLastPage = totalPages === currentPage;

      if (!!isRecentAlertsView && isLastPage || !alerts.length && isRecentAlertsView) {
        return (
          <Button
            onClick={handleShowMore}
            variant="outlined"
            color="primary"
            size='small'
          >
            {t('common:component.alert-table.action.view-more-alerts')}
          </Button>
        );
      }
    },
    [isLoading, pagination, mediumScreen, matchCount],
  );

  const renderBody = (): JSX.Element => {
    if (!alerts || !alerts.length) {
      if (isLoading) return <MiddleSpinner sx={{ height: 'auto', marginBottom: '2rem' }} />;

      if (isRecentAlertsView) {
        return (
          <Box sx={{ px: 2.5, pb: 3 }}>
            <Typography variant="body2" fontStyle="italic" color="text.secondary" sx={{ mb: 2 }} data-testid="no-results-for-30-days">
              {t('common:component.alert-table.hint.no-alerts', { count: maxDays })}
            </Typography>
            {showAllButtonComponent}
          </Box>
        );
      }

      return (
        <Typography variant="body2" fontStyle="italic" color="text.secondary" sx={{ px: 2.5, pb: 3 }} data-testid="no-results">
          {t('common:common.hint.list.no-results')}
        </Typography>
      );
    }

    if (flags['track-data-templated-alert-list-feature-temporary']) {
      const AlertRows: FC = () => <>{alerts.map((alert, i) => <AlertListItem template={rows} cardTemplate={cardTemplate} key={i} alert={alert} />)}</>;

      if (!headersLoaded || !cardLoaded || !rowLoaded || !headersRecentLoaded || !rowRecentLoaded) {
        return <></>;
      }

      if (mediumScreen) {
        return <Box data-testid='alert-table' role='table'><AlertRows /></Box>;
      }

      return <Table small data-testid='alert-table' headers={<>{evaluate(headers, {})}</>} pagination={paginationComponent} rows={<AlertRows />} />;
    }

    if (mediumScreen) {
      return (
        <AlertsTableLayoutMobile
          alerts={alerts}
          isLoading={isLoading}
          paginationComponent={paginationComponent}
          showPerson={showPerson}
          showThing={showThing}
          showAllButtonComponent={showAllButtonComponent}
        />
      );
    }

    return (
      <AlertsTableLayoutDesktop
        alerts={alerts}
        isLoading={isLoading}
        paginationComponent={paginationComponent}
        showFeature={showFeature}
        showGroups={showGroups}
        showPerson={showPerson}
        showThing={showThing}
        smallTable={smallTable}
        stickyHeader={stickyHeader}
        showAllButtonComponent={showAllButtonComponent}
      />
    );
  };

  return (
    <>
      {renderTitle?.(alerts, matchCount)}
      {renderBody()}
    </>
  );
};
