import { ThingEventSnapshot } from '@eagle/core-data-types';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { Maybe, SetState, Undefinable } from '../../types';
import { useHistorySearch } from '../entity-journey';
import { EventLocationData } from '../map';
import { ThingEventDialog } from './thing-event-dialog';

interface Context {
  open: boolean;
  setEventIndex: (index: number) => void;
  setEvents: (events: ThingEventSnapshot[]) => void;
  setOpen: (open: boolean) => void;
}

interface Props extends PropsWithChildren {
  setSelectedEvent: SetState<Maybe<EventLocationData>>;
}

const context = createContext<Undefinable<Context>>(undefined);

export const EventDialogProvider: FC<Props> = ({ children, setSelectedEvent }) => {
  const [open, setOpen] = useState(false);
  const [eventIndex, setEventIndex] = useState(0);
  const [events, setEvents] = useState<ThingEventSnapshot[]>([]);
  const { setClickedEventId, setSelectedEventId } = useHistorySearch();

  const closeDialog = (): void => {
    setOpen(false);
    setClickedEventId(null);
    setSelectedEvent(null);
    setSelectedEventId(null);
  };

  useEffect(() => {
    if (!open) return;
    const eventId = events?.[eventIndex]?.eventId;
    if (eventId) setSelectedEventId(eventId);
  }, [open, eventIndex, events, setSelectedEventId]);

  return (
    <context.Provider
      value={{
        open,
        setEventIndex,
        setEvents,
        setOpen,
      }}
    >
      {children}
      <ThingEventDialog
        data-testid="event-dialog"
        closeDialog={closeDialog}
        eventIndex={eventIndex}
        events={events}
        open={open}
        setEventIndex={setEventIndex}
      />
    </context.Provider>
  );
};

export const useEventDialog = (): Context => {
  const data = useContext(context);
  if (!data) throw new Error('Missing EventDialogProvider in tree above useEventDialog');
  return data;
};
