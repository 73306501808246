import { Box, ButtonProps, styled, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../auth';
import { LoadingButton } from '../loading-button';
import { MiddleSpinner } from '../middle-spinner';

interface MicroPortalProps {
  app: string;
  data?: Record<string, string>;
  title?: string;
  errorFallback?: ReactNode;
  loadingFallback?: ReactNode;
}

interface MicroPortalButtonProps extends ButtonProps { }

interface MicroPortalLinkProps extends PropsWithChildren {
  app: string;
  data?: Record<string, string>;
  loadingContent?: ReactNode;
  icon?: ReactNode;
  buttonProps?: MicroPortalButtonProps;
}

interface TokenResponse {
  sessionTransferToken: string;
}

const MicroFrame = styled('iframe')({
  border: 'none',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
});

const FallbackComponent: React.FC = () => {
  const { t } = useTranslation(['common']);
  return <Box sx={{ p: 3, textAlign: 'center' }}>
    <Typography>{t('common:page.sign-in.hint.failure.unauthorized_client')}</Typography>
  </Box>;
};

const createParams = (params: Record<string, string>): string => {
  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    return '';
  }
  return Object.entries(params).filter(([key, value]) => { return key !== '__proto__' && key !== 'constructor' && typeof value === 'string'; }).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
};

export const MicroPortal: React.FC<MicroPortalProps> = ({ app, data = {}, title = '', errorFallback = FallbackComponent, loadingFallback = <MiddleSpinner /> }) => {
  const { axios, account } = useAuthenticated();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>();

  useEffect(() => {
    setLoading(true);
    let mounted = true;

    axios.post<TokenResponse>(`/api/v1/auth/micro-portal/${app}/init`).then((res) => {
      if (!mounted) return;
      if (res.data.sessionTransferToken) {
        setToken(res.data.sessionTransferToken);
        setError(false);
      }
    }).catch(() => {
      if (!mounted) return;
      setToken(null);
      setError(true);
    }).finally(() => {
      if (!mounted) return;
      setLoading(false);
    });
    return () => {
      mounted = false;
    };
  }, [axios, app, setToken, setError, setLoading]);

  if (loading) {
    return <>{loadingFallback}</>;
  }

  if (error) {
    return <>{errorFallback}</>;
  }

  if (!account.homeDomain || !token) {
    return <></>;
  }

  const src = `${new URL(import.meta.url).origin}/extension/${app}?${createParams({ auth: token, domain: account.homeDomain, ...data })}`;
  return <MicroFrame src={src} scrolling='no' title={title} sandbox="allow-scripts allow-same-origin allow-forms" referrerPolicy="no-referrer" />;
};

export const MicroPortalLink: React.FC<MicroPortalLinkProps> = ({ app, data = {}, children, loadingContent, buttonProps }) => {
  const { axios, account } = useAuthenticated();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation(['common']);

  const loadUrl = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await axios.post<TokenResponse>(`/api/v1/auth/micro-portal/${app}/init`);
      if (res.data.sessionTransferToken) {
        const src = `${new URL(import.meta.url).origin}/extension/${app}?${createParams({
          auth: res.data.sessionTransferToken,
          domain: account.homeDomain,
          ...data,
        })}`;
        const newWindow = window.open(src, '_blank');
        if (!newWindow) {
          throw new Error('Popup blocked');
        }
      }
    } catch (error) {
      enqueueSnackbar(error instanceof Error ? error.message : t('common:page.sign-in.hint.failure.unauthorized_client'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return <LoadingButton {...buttonProps} onClick={() => loadUrl()} isLoading={loading} loadingCaption={loadingContent || children}>{children}</LoadingButton>;
};
