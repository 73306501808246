/* eslint-disable react-hooks/exhaustive-deps */
import { ServiceHistory, ServiceMetric, ServiceType } from '@eagle/core-data-types';
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, useMemo } from 'react';
import { evaluate } from '../../evaluator';
import { useSmallScreen, useUiTemplate } from '../../hooks';
import { SERVICE_HISTORY_LIST_HEADERS, SERVICE_HISTORY_LIST_ITEM, SERVICE_HISTORY_LIST_ITEM_MOBILE } from '../../ui-templates';
import { testid } from '../../util';
import { MiddleSpinner } from '../middle-spinner';
import { ServiceHistoryExtended } from './service-history.types';

interface Props {
  services: ServiceHistory[];
  serviceTypes: ServiceType[];
  serviceMetrics: ServiceMetric[];
  onClick: (serviceId?: ServiceHistoryExtended) => void;
}

export const ServiceHistoryListView: FC<Props> = ({ services, serviceTypes, serviceMetrics, onClick }) => {
  const smallScreen = useSmallScreen();
  const { template: headersTemplate, loaded: headersLoaded } = useUiTemplate('service-history-list-headers', SERVICE_HISTORY_LIST_HEADERS);
  const { template: rowTemplate, loaded: rowLoaded } = useUiTemplate('service-history-list-item', SERVICE_HISTORY_LIST_ITEM);
  const { template: rowTemplateMobile, loaded: rowMobileLoaded } = useUiTemplate('service-history-list-item-mobile', SERVICE_HISTORY_LIST_ITEM_MOBILE);

  const updatedThingServices = useMemo(() => {
    return services.map((serviceItem) => {
      const serviceType = serviceTypes.find((type) => type.serviceTypeId === serviceItem.serviceTypeId);
      const serviceTypeDisplay = serviceType ? serviceType.display : '';
      const serviceTypeDetails = serviceType ? serviceType.details : '';

      // Map the service metrics to include the value
      const thingServiceMetrics = serviceMetrics.map((metric) => ({
        serviceMetricId: metric.serviceMetricId,
        display: metric.display,
        type: metric.type,
        unit: metric.unit,
        value: serviceItem.serviceMetrics[metric.serviceMetricId] || 0,
      }));

      return {
        ...serviceItem,
        serviceTypeDetails,
        serviceTypeDisplay,
        thingServiceMetrics,
      } as ServiceHistoryExtended;
    });
  }, [services, serviceTypes, serviceMetrics]);

  if (!rowLoaded || !headersLoaded || !rowMobileLoaded) {
    return <MiddleSpinner />;
  }

  const headers = smallScreen ? null : <>{evaluate(headersTemplate, serviceMetrics)}</>;

  return (
    <TableContainer sx={{ overflow: smallScreen ? 'auto' : undefined, '& +.MuiPagination-root': { mt: 3 } }}>
      <Table aria-label="service-history-table" component="div" data-testid="service-history-table" size={smallScreen ? 'medium' : 'small'}>
        <TableHead component="div">
          {headers}
        </TableHead>
        <TableBody component="div">
          {updatedThingServices?.map((service, i) => (
            <TableRow component="div" key={i} data-testid={testid`service-history-table-row-${i}`} hover onClick={() => onClick(service)} sx={{ cursor: 'pointer' }}>
              {smallScreen ? <>{evaluate(rowTemplateMobile, { ...service })}</> : <>{evaluate(rowTemplate, { ...service })}</>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
